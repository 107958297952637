<template>
  <path
    d="M16 8.5C16 12.9183 12.4183 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5Z"
    fill="currentColor"
  />
  <path
    d="M5 13.5V4.5H8.60805C9.3472 4.5 9.96734 4.62891 10.4685 4.88672C10.9727 5.14453 11.3532 5.49902 11.6101 5.9502C11.87 6.39844 12 6.9082 12 7.47949C12 8.05664 11.87 8.56934 11.6101 9.01758C11.3501 9.46582 10.9664 9.81885 10.4591 10.0767C9.95168 10.3315 9.32685 10.459 8.58456 10.459H6.19329V9.11865H8.34966C8.78188 9.11865 9.13579 9.04834 9.41141 8.90771C9.68702 8.76709 9.8906 8.57373 10.0221 8.32764C10.1568 8.08154 10.2242 7.79883 10.2242 7.47949C10.2242 7.16016 10.1568 6.87891 10.0221 6.63574C9.8906 6.39258 9.68546 6.20361 9.40671 6.06885C9.1311 5.93115 8.77561 5.8623 8.34027 5.8623H6.74295V13.5H5Z"
    fill="white"
  />
</template>
